<template>
  <div id="viewer3D">
    <div class="controls">
      <router-link :to="{ name: 'landing' }" class="viewer3Dlogo">
        <img src="./../../assets/images/viewer3DLogo.png" alt />
      </router-link>
      <div class="viewer3DfileUpload" v-if="isLocalView">
        <label for="upload3DFile" class="viewer3DfileUploadLabel">
          <icon name="plus" class="viewer3DfileUploadLabelIcon"></icon>Добавить модель
        </label>
        <input type="file" class="viewer3DfileUploadInput" id="upload3DFile" @change="loadLocalFile" />
      </div>
      <div class="viewerTitle" v-if="viewerTite">{{ viewerTite }}</div>
      <a href="#" class="viewer3Dclose" v-on:click.prevent.stop="goBack">
        <icon name="times" scale="1.8"></icon>
      </a>
    </div>
    <div id="fileListControls" :class="{ active: isFileListControls }">
      <a href="#" class="fileListToogle" v-on:click.prevent.stop="isFileListControls = !isFileListControls">
        <img src="./../../assets/images/viewer3DIcon.png" class="leftIcon" alt />
        <span>Список файлов</span>
        <icon name="caret-left" v-if="!isFileListControls" class="rightIcon" scale="1.5"></icon>
        <icon name="caret-down" v-if="isFileListControls" class="rightIcon" scale="1.5"></icon>
      </a>
      <div class="fileListElements" v-if="isFileListControls">
        <div class="fileListItem" v-for="(item, index) in stlFilesFiltered" :key="index">
          <div class="fileListItemOpacity">
            <div class="opacityIcon opacity_0"></div>
            <input
              type="range"
              min="0"
              max="100"
              class="opacityType"
              :data-model-name="item.name"
              v-model="item.options.opacity"
              v-on:input="selectOpacity"
            />
            <div class="opacityIcon opacity_100"></div>
          </div>
          <span>{{ item.name }}</span>
          <span v-if="item.progress != 100">{{ item.progress }}%</span>
          <div class="fileListItemColor" v-if="isLocalView">
            <div class="fileListItemColor-preview" :class="item.options.material"></div>
            <select
              class="fileListItemColor-select"
              :data-model-name="item.name"
              :data-model-index="index"
              v-model="item.options.material"
              v-on:change="selectColor"
            >
              <option>bone</option>
              <option>enamel</option>
              <option>gum</option>
              <option>steel</option>
              <option>aluminium</option>
              <option>green</option>
              <option>blue</option>
              <option>pink</option>
              <option>purple</option>
              <option>orange</option>
            </select>
          </div>
          <a href="#" class="removeModel" v-if="isLocalView" v-on:click.prevent.stop="removeModel(index)">
            <icon name="times"></icon>
          </a>
        </div>
        <div class="fileListItem" v-for="(group, name) in stlFilesGroups" :key="name">
          <div class="fileListItemOpacity">
            <div class="opacityIcon opacity_0"></div>
            <input
              type="range"
              min="0"
              max="100"
              class="opacityType"
              :data-group="name"
              v-model="group.value"
              v-on:input="selectOpacityGroup"
            />
            <div class="opacityIcon opacity_100"></div>
          </div>
          <span>{{ name }}</span>
        </div>
        <div v-if="stlFiles.length == 0" class="emptyFiles">
          <span>Нет загруженных файлов</span>
        </div>
      </div>
    </div>
    <div id="rendererContainer" ref="rendererContainer">
      <preloader :progress="loadingProgress"></preloader>
      <wattermark></wattermark>
      <label for="upload3DFile" class="uploadFirst3Dfile" v-if="isLocalView && stlFiles.length == 0">
        <icon name="plus" scale="4"></icon>
        <br />
        <span>Import model</span>
      </label>
    </div>
  </div>
</template>
<script>
import preloader from "./../preloader/preloader.vue";
import wattermark from "./../wattermark/wattermark.vue";

var isWebglEnabled = require("detector-webgl");
import * as THREE from "three";
var STLLoader = require("three-stl-loader")(THREE);
var TrackballControls = require("./TrackballControls");

export default {
  name: "viewer3D",
  components: { preloader, wattermark },
  data() {
    return {
      isLocalView: true,
      isFileListControls: false,

      endpoint: "viewer_3d/",
      current3dType: this.$route.params.type,
      current3dSet: this.$route.params.set,
      categoryName: "",

      animationFrame: null,
      scene: null,
      camera: null,
      cameraZoom: 0.3,
      controls: null,
      directionalLight: null,
      renderer: null,
      rendererContainer: null,
      reflectionCube: null,
      mainGroup: null,
      centerPoint: {
        x: 0,
        y: 0,
        z: 0,
        xArray: [],
        yArray: [],
        zArray: [],
      },

      viewerTite: "",
      stlFiles: [],
      stlFilesGroups: {},
    };
  },
  computed: {
    loadingProgress() {
      var result = this.stlFiles.reduce(function (sum, current) {
        return sum + current.progress;
      }, 0);

      if (result == 0) {
        return 100;
      } else {
        return (result / this.stlFiles.length).toFixed();
      }
    },
    stlFilesFiltered() {
      return this.stlFiles.filter((el) => el.options.parent);
    },
  },
  methods: {
    init() {
      this.scene = new THREE.Scene();
      this.addCamera();
      //this.addAxes();
      this.addRenderer();
      this.addControls();
      this.addLight();
      this.addMap();
      this.addMainGroup();
      this.chekStlFiles();
      this.animate();
    },
    addCamera() {
      // Обьявление камеры
      let width = this.rendererContainer.clientWidth * this.cameraZoom;
      let height = this.rendererContainer.clientHeight * this.cameraZoom;
      this.camera = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 1000);

      // Old perspective camera
      // this.camera = new THREE.PerspectiveCamera(45, width / height, 1, 1000);

      this.camera.position.set(-75, 75, 75);
      this.camera.lookAt(this.scene.position);

      this.camera.updateProjectionMatrix();
    },
    addAxes() {
      // Добавление осей координат (Опционально)
      var axes = new THREE.AxisHelper(20);
      this.scene.add(axes);
    },
    addRenderer() {
      // Обьявление области рендеринга
      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      this.renderer.setSize(this.rendererContainer.clientWidth, this.rendererContainer.clientHeight);
      this.rendererContainer.appendChild(this.renderer.domElement);
      this.renderer.setClearColor(0x423c63, 0);
      //this.renderer.gammaInput = true;
      //this.renderer.gammaOutput = true;
    },
    addControls() {
      // Добавление управления
      this.controls = new TrackballControls(this.camera, this.rendererContainer);
      this.controls.rotateSpeed = 4.0;
      this.controls.zoomSpeed = 4.0;
      this.controls.panSpeed = 0.8;
      this.controls.noZoom = false;
      this.controls.noPan = false;
      this.controls.staticMoving = true;
      this.controls.dynamicDampingFactor = 0.3;

      this.controls.addEventListener("change", this.render);
    },
    addLight() {
      // Добавление источников света
      this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.9); //0.9
      this.scene.add(this.directionalLight);
      let light = new THREE.AmbientLight(0x404040); // soft white light 404040
      this.scene.add(light);
    },
    addMap() {
      // env map
      var path = "/viewer_3d/textures/";
      var format = ".jpg";
      var urls = [
        path + "4" + format,
        path + "4" + format,
        path + "5" + format,
        path + "3" + format,
        path + "4" + format,
        path + "4" + format,
      ];

      this.reflectionCube = new THREE.CubeTextureLoader().load(urls);
    },
    addMainGroup() {
      // Добавление основной группы
      this.mainGroup = new THREE.Group();
      this.mainGroup.updateMatrixWorld(true);
      this.scene.add(this.mainGroup);
    },
    chekStlFiles() {
      // Проверка пред загруженных файлов
      if (this.stlFiles.length) {
        this.isFileListControls = true;
        for (var i = 0; i < this.stlFiles.length; i++) {
          if (typeof this.mainGroup.getObjectByName(this.stlFiles[i].name) === undefined) {
            this.stlFiles[i].progress = this.isLocalView ? 100 : 0;
            this.addToMainMesh(this.stlFiles[i], i);
          } else {
            this.stlFiles[i].progress = 100;
            console.log("document is all ready rendered");
          }
        }
      } else {
        this.mainGroup.children = new Array();
        this.render();
      }
    },
    addToMainMesh(stlFile, index) {
      // Добавление файла в MainMesh
      var manager = new THREE.LoadingManager();
      manager.onStart = function (url, itemsLoaded, itemsTotal) {
        // console.log( 'Started loading file:');
      };
      manager.onLoad = function () {
        // console.log( 'Loading complete!');
      };
      manager.onProgress = function (url, itemsLoaded, itemsTotal) {
        // console.log( 'Loading file:' );
      };
      manager.onError = function (url) {
        // console.log( 'There was an error loading ' + url );
      };

      const loader = new STLLoader(manager);

      loader.load(
        stlFile.url,
        (object) => {
          object.computeBoundingSphere();
          object.computeVertexNormals();
          var attrib = object.getAttribute("position");
          if (attrib === undefined) {
            throw new Error("a given BufferGeometry object must have a position attribute.");
          }
          var positions = attrib.array;
          var vertices = [];
          for (let i = 0, n = positions.length; i < n; i += 3) {
            var x = positions[i];
            var y = positions[i + 1];
            var z = positions[i + 2];
            vertices.push(new THREE.Vector3(x, y, z));
          }
          var faces = [];
          for (let i = 0, n = vertices.length; i < n; i += 3) {
            faces.push(new THREE.Face3(i, i + 1, i + 2));
          }

          var geometry = new THREE.Geometry();
          geometry.vertices = vertices;
          geometry.faces = faces;
          geometry.computeFaceNormals();
          geometry.mergeVertices();
          geometry.computeVertexNormals();
          var buffer_g = new THREE.BufferGeometry();
          buffer_g.fromGeometry(geometry);

          let materialName =
            typeof stlFile.options !== undefined && typeof stlFile.options.material !== undefined
              ? stlFile.options.material
              : "default";
          let material = this.getItemMaterial(materialName);
          // material.flatShading = true;

          var mesh = new THREE.Mesh(buffer_g, material);
          mesh.name = stlFile.name;
          mesh.castShadow = true;
          mesh.receiveShadow = true;

          this.mainGroup.add(mesh);

          if (typeof stlFile.options.parent == "string" && stlFile.options.parent) {
            if (!this.stlFilesGroups[stlFile.options.parent]) {
              this.stlFilesGroups[stlFile.options.parent] = {
                value: "100",
                models: [stlFile.name],
              };
            } else {
              this.stlFilesGroups[stlFile.options.parent].models.push(stlFile.name);
            }
          }

          let opacityType =
            typeof stlFile.options != undefined && typeof stlFile.options.opacity != undefined
              ? stlFile.options.opacity
              : "0";
          this.setOpacity(stlFile.name, opacityType);

          this.render();
        },
        (e) => {
          this.progressLoader(e.loaded, e.total, index);
        },
      );
    },
    getItemMaterial(materialType = null) {
      let material = null;
      switch (materialType) {
        case "bone":
          material = new THREE.MeshStandardMaterial({
            color: 0xffefc9,
            roughness: 0.8,
            metalness: 0.3,
            side: THREE.DoubleSide,
          });
          break;
        case "enamel":
          material = new THREE.MeshStandardMaterial({
            color: 0xe3dccc,
            roughness: 0.4,
            metalness: 0.05,
            side: THREE.DoubleSide,
          });
          break;
        case "gum":
          material = new THREE.MeshStandardMaterial({
            color: 0xac6870,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          });
          break;
        case "steel":
          material = new THREE.MeshStandardMaterial({
            color: 0xf2f2f2, //e1e1e1
            roughness: 0.65, //0.15
            metalness: 1, //0.35
            envMap: this.reflectionCube,
            side: THREE.DoubleSide,
          });
          break;
        case "aluminium":
          material = new THREE.MeshStandardMaterial({
            color: 0xf2f2f2, //e1e1e1
            roughness: 0.35, //0.15
            metalness: 1, //0.35
            envMap: this.reflectionCube,
            side: THREE.DoubleSide,
          });
          break;
        case "green":
          material = new THREE.MeshStandardMaterial({
            color: 0x4cbb17,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          });
          break;
        case "blue":
          material = new THREE.MeshStandardMaterial({
            color: 0x87cefa,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          });
          break;
        case "pink":
          //material = new THREE.MeshStandardMaterial({color: 0xdb7093, roughness: 0.3, metalness: 0.05, side:THREE.DoubleSide}); //old
          material = new THREE.MeshStandardMaterial({
            color: 0xea899a,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          }); // new
          break;
        case "purple":
          // old 0x7851a9 0xdda0dd
          material = new THREE.MeshStandardMaterial({
            color: 0xb664c0,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          }); // new
          break;
        case "black":
          material = new THREE.MeshStandardMaterial({
            color: 0x000000,
            roughness: 0.6,
            metalness: 0.0,
            side: THREE.DoubleSide,
          }); // new
          break;
        case "orange":
          // old 0xff8243 0xefa94a
          material = new THREE.MeshStandardMaterial({
            color: 0xe2b67b,
            roughness: 0.3, // 0.3
            metalness: 0.05, //0.05
            // envMap: this.reflectionCube,
            side: THREE.DoubleSide,
          }); // new
          break;
        default:
          material = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            roughness: 1,
            metalness: 1,
            side: THREE.DoubleSide,
          });
      }
      return material;
      //return new THREE.MeshStandardMaterial({color: 0xFF8243, side:THREE.DoubleSide});
    },
    selectOpacityGroup(event) {
      this.setOpacityGroup(event.target.dataset.group, event.target.value);
      this.render();
    },
    setOpacityGroup(groupName, opacityType) {
      let opacity = parseInt(opacityType) / 100;
      let models = this.stlFilesGroups[groupName].models;

      for (var i = 0; i < models.length; i++) {
        var editModel = this.mainGroup.getObjectByName(models[i]);
        if (opacity == 1) {
          editModel.material.transparent = false;
          editModel.material.opacity = 1;
          editModel.visible = true;
        } else if (opacity == 0) {
          editModel.visible = false;
        } else {
          editModel.material.transparent = true;
          editModel.material.opacity = opacity;
          editModel.visible = true;
        }
      }
    },
    selectOpacity(event) {
      this.setOpacity(event.target.dataset.modelName, event.target.value);
      this.render();
    },
    setOpacity(modelName, opacityType) {
      let editModel = this.mainGroup.getObjectByName(modelName);
      let opacity = parseInt(opacityType) / 100;
      if (opacity == 1) {
        editModel.material.transparent = false;
        editModel.material.opacity = 1;
        editModel.visible = true;
      } else if (opacity == 0) {
        editModel.visible = false;
      } else {
        editModel.material.transparent = true;
        editModel.material.opacity = opacity;
        editModel.visible = true;
      }
    },
    selectColor(event) {
      this.setColor(event.target.dataset.modelName, event.target.dataset.modelIndex, event.target.value);
    },
    setColor(modelName, index, material) {
      var editModel = this.mainGroup.getObjectByName(modelName);
      let tmpTransparent = editModel.material.transparent;
      let tmpOpacity = editModel.material.opacity;
      let tmpVisible = editModel.visible;

      editModel.material = this.getItemMaterial(material);

      editModel.material.transparent = false;
      editModel.material.opacity = tmpOpacity;
      editModel.visible = tmpVisible;

      this.stlFiles[index].options.material = material;
      this.render();
    },
    calcCenter() {
      // Расчет центра всех моделей
      this.centerPoint = {
        x: 0,
        y: 0,
        z: 0,
        xArray: [],
        yArray: [],
        zArray: [],
      };
      for (var i = 0; i < this.mainGroup.children.length; i++) {
        this.centerPoint.xArray.push(this.mainGroup.children[i].geometry.boundingSphere.center.x);
        this.centerPoint.yArray.push(this.mainGroup.children[i].geometry.boundingSphere.center.y);
        this.centerPoint.zArray.push(this.mainGroup.children[i].geometry.boundingSphere.center.z);
      }
      if (this.centerPoint.xArray.length) {
        this.centerPoint.x =
          -[].reduce.call(this.centerPoint.xArray, function (p, c) {
            return c + p;
          }) / this.centerPoint.xArray.length;
      }
      if (this.centerPoint.yArray.length) {
        this.centerPoint.y =
          -[].reduce.call(this.centerPoint.yArray, function (p, c) {
            return c + p;
          }) / this.centerPoint.yArray.length;
      }
      if (this.centerPoint.zArray.length) {
        this.centerPoint.z =
          -[].reduce.call(this.centerPoint.zArray, function (p, c) {
            return c + p;
          }) / this.centerPoint.zArray.length;
      }
      this.mainGroup.position.set(this.centerPoint.x, this.centerPoint.y, this.centerPoint.z);
    },
    render() {
      // Ререндеринг сцены
      this.renderer.render(this.scene, this.camera);
      this.directionalLight.position.copy(this.camera.position);
      this.calcCenter();
    },
    animate() {
      // Анимация сцены
      this.animationFrame = requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
      this.controls.update();
    },
    resize() {
      if (this.camera && this.renderer) {
        this.renderer.setSize(this.rendererContainer.clientWidth, this.rendererContainer.clientHeight);

        let width = this.rendererContainer.clientWidth * this.cameraZoom;
        let height = this.rendererContainer.clientHeight * this.cameraZoom;
        this.camera.left = width / -2;
        this.camera.right = width / 2;
        this.camera.top = height / 2;
        this.camera.bottom = height / -2;

        this.camera.updateProjectionMatrix();
      }
    },
    progressLoader(loaded, total, index) {
      let progress = Math.round((loaded / total) * 100);
      this.stlFiles[index] = Object.assign({}, this.stlFiles[index], {
        progress: progress,
      });
      this.$set(this.stlFiles, index, this.stlFiles[index]);
    },
    removeModel(index) {
      this.$delete(this.stlFiles, index);
      this.chekStlFiles();
    },
    goBack() {
      if (this.current3dType == "tooth_style") {
        this.$router.push({ name: "toothStyles" });
      } else if (this.current3dType == "tooth_style_expression") {
        this.$router.push({ name: "toothStylesExpression" });
      } else if (this.current3dType == "orthodontic_system") {
        this.$router.push({ name: "orthodonticSystems" });
      } else if (this.current3dType == "attachments_library") {
        this.$router.push({ name: "attachmentsLibrary" });
      } else if (this.current3dType == "visualization") {
        switch (this.categoryName) {
          case "surgery":
            this.$router.push({ name: "visualization_surgery" });
            break;
          case "orthodontics":
            this.$router.push({ name: "visualization_orthodontics" });
            break;
          case "orthopedics":
            this.$router.push({ name: "visualization_orthopedics" });
            break;
          case "terapy":
            this.$router.push({ name: "visualization_terapy" });
            break;
          default:
            this.$router.push({ name: "visualization" });
        }
      } else if (this.current3dType == "stlViewer") {
        this.$router.push({ name: "stlViewer" });
      } else {
        this.$router.push({ name: "landing" });
      }
    },
    // Добавить файл c локального диска
    loadLocalFile(e) {
      let loadedFile = e.target.files[0];
      if (loadedFile && /\.(stl)$/i.test(loadedFile.name)) {
        let fileName = loadedFile.name.substring(0, loadedFile.name.lastIndexOf("."));
        let newStlFile = {
          name: fileName,
          url: "",
          options: { material: "orange", opacity: 100 },
          progress: 0,
        };
        let index = this.stlFiles.push(newStlFile) - 1;

        let fileReader = new FileReader();
        fileReader.onloadend = (file) => {
          this.stlFiles[index].url = fileReader.result;
          e.target.value = "";
          this.chekStlFiles();
        };
        fileReader.onprogress = (e) => {
          this.progressLoader(e.loaded, e.total, index);
        };
        fileReader.readAsDataURL(loadedFile);
      } else {
        console.log("Uploaded file is not supported!");
      }
    },
    loadRemoteFiles() {
      this.$http.get(this.endpoint + this.current3dType + "/" + this.current3dSet).then((response) => {
        // console.log(response.data);
        this.viewerTite = response.data.title ? response.data.title : "";
        this.categoryName = response.data.category ? response.data.category : "";
        if (response.data.stlFiles) {
          this.stlFiles = response.data.stlFiles;
          this.chekStlFiles();
        } else {
          console.log("There is no 3D sets!");
        }
      });
    },
  },
  mounted() {
    this.rendererContainer = this.$refs.rendererContainer;

    if (!isWebglEnabled) {
      console.log("webGL is not supported!");
    } else {
      this.init();
    }
  },
  created: function () {
    window.addEventListener("resize", this.resize, false);
    if (this.current3dType && this.current3dSet) {
      this.isLocalView = false;
      this.loadRemoteFiles();
    }
  },
  destroyed: function () {
    window.removeEventListener("resize", this.resize, false);
    cancelAnimationFrame(this.animationFrame);
  },
};
</script>

<style lang="sass">
#viewer3D
	.controls
		display: flex
		align-items: center
		justify-content: space-between
		background-color: #12123d
		padding: 0 0.5rem
		height: 50px
		border-bottom: 2px solid #494a8f
		.viewer3Dlogo
			display: flex
			align-items: center
			justify-content: center
			height: inherit
			img
				display: block
				height: 30px
		.viewer3DfileUpload
			.viewer3DfileUploadLabel
				display: flex
				align-items: center
				justify-content: center
				height: inherit
				color: #fff
				cursor: pointer
				.viewer3DfileUploadLabelIcon
					margin-right: 0.5rem
			.viewer3DfileUploadInput
				display: none
		.viewerTitle
			color: #fff
			font-size: 1.2rem
		.viewer3Dclose
			display: flex
			align-items: center
			justify-content: center
			height: inherit
			color: #fff
#fileListControls
	display: block
	position: absolute
	top: 50px
	right: 0
	z-index: 5
	transition: all 0.2s
	&.active
		background: #fff
		min-width: 300px
		.fileListToogle
			border-color: #ddd
	.fileListToogle
		display: flex
		align-items: center
		color: #494a8f
		padding: 0.5rem 0.5rem
		cursor: pointer
		border: 1px solid transparent
		justify-content: space-between
		.leftIcon
			margin-right: 0.5rem
		.rightIcon
			margin-left: 0.5rem
	.fileListElements
		display: flex
		flex-direction: column
		border: 1px solid #ddd
		border-top: none
		overflow-y: scroll
		max-height: calc(100vh - 120px)
		.emptyFiles
			display: flex
			align-items: center
			justify-content: center
			padding: .5rem 0
		.fileListItem
			display: flex
			justify-content: space-between
			padding: 0 10px
			border-bottom: 1px solid #ddd
			min-height: 45px
			align-items: center
			&:last-child
				border-bottom: none
			.fileListItemOpacity
				display: flex
				.opacityIcon
					content: ''
					display: block
					width: 20px
					height: 30px
					background-size: cover
					&.opacity_0
						background-image: url('../../assets/images/opacity_0.jpg')
					&.opacity_100
						background-image: url('../../assets/images/opacity_100.jpg')
				input.opacityType
					width: 70px
					margin: 0 5px

			.fileListItemColor
				display: flex
				align-items: center
				margin-left: 5px
				.fileListItemColor-preview
					display: inline-block
					height: 25px
					width: 25px
					margin-right: 2px
					background-color: #eee
					&.bone
						background-color: #ffefc9
					&.enamel
						background-color: #e3dccc
					&.gum
						background-color: #ac6870
					&.steel
						background-color: #e1e1e1
					&.green
						background-color: #4cbb17
					&.blue
						background-color: #87cefa
					&.pink
						background-color: #ea899a
					&.purple
						background-color: #b664c0
					&.orange
						background-color: #e2b67b

				.fileListItemColor-select
					display: inline-block;
					border: 1px solid #eee;
					color: #333;
					font-size: 14px;
					padding: 0 5px;
					height: 25px;
					// &.active
					// 	border: 2px solid #ffffff
					// &:nth-child(1)
					// 	background-color: #87CEFA
					// &:nth-child(2)
					// 	background-color: #DB7093
					// &:nth-child(3)
					// 	background-color: #7851A9
					// &:nth-child(4)
					// 	background-color: #FF8243
			.removeModel
				display: flex
				align-items: center
				margin-left: 0.5rem
				transition: all 0.3s
				&:hover
					color: red

#rendererContainer
	display: block
	position: relative
	height: calc(100vh - 50px)
	box-sizing: border-box
	width: 100%
	.uploadFirst3Dfile
		display: block
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		padding: 2rem 2rem
		background: rgba(0, 0, 0, 0.41)
		color: #fff
		font-size: 1.2rem
		text-align: center
		cursor: pointer
	canvas
		display: block
		background: rgb(176,174,196)
		background: radial-gradient(ellipse at center, rgba(239,239,244,1) 0%,rgba(191,191,196,1) 100%)
</style>
